import * as React from "react"
// import { Link } from "gatsby"
import styled from "@emotion/styled"
import UtilMenu from "./utilmenu"
import MainNavigation from "./mainNavigation"

const HeaderContainer = styled.div`
  position: sticky;
  top: 0;
  z-index: 999;
`

const Header = () => (
  <HeaderContainer>
    <UtilMenu />
    <MainNavigation />
  </HeaderContainer>
)

export default Header
