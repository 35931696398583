import * as React from "react"
import { Link } from "gatsby"
import styled from "@emotion/styled"

import IconPhone from "../../images/icon-phone.inline.svg"
import IconEmail from "../../images/icon-email.inline.svg"


const InnerContainer = styled.div`
    width: 100%;
    display: flex;
    justify-content: flex-end;
    font-size: 10px;
    font-family: "BS";
    letter-spacing: 0.03rem;
    font-weight: 400;
    color: var(--white);
    text-align: right;
    text-transform: uppercase;
    align-items: center;

    @media (min-width: 980px) {
        max-width: 1440px;
        margin: 0 auto;
    }
`

const MainCta = styled(Link)`
    background: var(--gray);
    color: var(--white);
    padding: 1rem 1.5rem;
    transition: filter .3s ease;
    filter: brightness(1);

    &:hover {
        filter: brightness(1.25);
        transition: filter .3s ease;
    }
`

const Phone = styled.a`
    display: none;
    flex-direction: row;
    align-items: center;
    filter: brightness(1);
    transition: filter .3s ease;
    margin-right: 2rem;

    &:hover {
        filter: brightness(0.8);
        transition: filter .3s ease;
    }

    svg {
        fill: white;
        height: 16px;
        margin-right: .5rem;
    }

    @media (min-width: 620px) {
        display: flex;
    }

    @media (min-width: 880px) {
        margin-right: 0;
    }
`

const Email = styled.a`
    flex-direction: row;
    align-items: center;
    display: none;
    filter: brightness(1);
    transition: filter .3s ease;
    margin: 0 2rem;

    &:hover {
        filter: brightness(0.8);
        transition: filter .3s ease;
    }

    @media (min-width: 880px) {
        display: flex;
    }

    svg {
        fill: white;
        height: 12px;
        margin-right: .5rem;
    }
`

const Container = styled.div`
    background: var(--black);
    width: 100%;
    display: none;

    @media (min-width: 550px) {
        display: flex;
    }

`


const UtilMenu = () => (
    <Container>
        <InnerContainer>
            <Phone href="tel:+1-281-668-9108"><IconPhone />+1 (281) 668-9108</Phone>
            <Email href="mailto:contact@orionconstructors.com"><IconEmail />contact@orionconstructors.com</Email>
            <MainCta to="/quote">
                Request A Proposal
            </MainCta>
        </InnerContainer>
    </Container>
)

export default UtilMenu
