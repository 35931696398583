import * as React from "react"
import styled from "@emotion/styled"


const Container = styled.div`
    background: var(--primary);
    padding: 1.5rem 0;
    font-family: "BS";
    text-transform: uppercase;
    letter-spacing: 0.03rem;
    display: flex;
    flex: 1;

    @media (max-width: 600px) {
        display: none;
    }
`

const Navigation = styled.div`
    font-size: 15px;
    font-weight: 500;
    display: flex;
    flex: 1;
    justify-content: flex-end;
    width: 100%;
`

const PrimaryNavigation = () => (
    <Container>
        <Navigation>
        </Navigation>
    </Container>
)

export default PrimaryNavigation
