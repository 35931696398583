import * as React from "react"
import { Link } from "gatsby"
import styled from "@emotion/styled"

import PrimaryNavigation from "./primaryNavigation"
import MobileNavigation from "./mobileNavigation"
import Logo from "../../images/logo.inline.svg"

const Container = styled.div`
    display: flex;
    background: var(--primary);
    width: 100%;
`

const LogoContainer = styled.div`
    background: var(--black);
    display: flex;
    align-items: center;
    /* margin-top: -2.75rem; */
    padding-left: 1rem;
    padding-right: 1rem;
    z-index: 999;
    position: absolute;
    height: 90px;

    a {
        cursor: pointer;
    }

    svg {
        height: 60px;
        width: auto;
        border-bottom: 1px solid white;
        padding-bottom: 0.75rem;
        margin-top: .75rem;
        cursor: pointer;

        @media (max-width: 500px) {
            height: 50px;
        }
    }

    @media (min-width: 550px) {
        margin-top: -2.75rem;
        padding-left: 2rem;
        padding-right: 2rem;
        height: 110px;
    }

    @media (min-width: 970px) {
        padding-top: 1.15rem;
        height: 130px;
    }
`

const InnerContainer = styled.div`
    display: flex;
    width: 100%;

    @media (min-width: 980px) {
        width: 1440px;
        margin: 0 auto;
        justify-content: space-between;
    }
`

const PrimaryNavigationContainer = styled.div`
    display: flex;
    flex: 1;
`

const MobileNavigationContainer = styled.div`
    display: flex;
    flex: 1;

    @media (min-width: 970px) {
        display: none;
    }
`

const MainNavigation = () => (
    <Container>
        <InnerContainer>
            <LogoContainer>
                <Link to="/">
                <Logo />
                </Link>
            </LogoContainer>
            <PrimaryNavigationContainer>
                <PrimaryNavigation />
            </PrimaryNavigationContainer>
            <MobileNavigationContainer>
                <MobileNavigation />
            </MobileNavigationContainer>
        </InnerContainer>
    </Container>
)

export default MainNavigation
