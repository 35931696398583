import * as React from "react"
import { Link } from "gatsby"
import styled from "@emotion/styled"
import { StaticImage } from "gatsby-plugin-image"

export function Logo() {
    return <StaticImage src="../../images/logo-full.png" alt="" />
}

const FooterContainer = styled.footer`
  background: var(--black);
  color: var(--white);
  padding: 3rem 4rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  font-family: "BS";
  letter-spacing: 0.01rem;
  align-items: center;
`

const LogoContainer = styled.div`
  width: 160px;
  height: auto;
  margin-bottom: 2rem;
`

const FooterNavigation = styled.div`
  display: flex;
  flex-direction: column;

  p {
    font-size: .7rem;
    margin-top: 2rem;
    text-align: center;
  }
`

const FooterNavigationPrimary = styled.div`
  font-size: .9rem;
  text-transform: uppercase;
  font-weight: 500;
  display: flex;
  flex-direction: column;
  text-align: center;
  
  a {
    margin-bottom: 1.5rem;

    @media (min-width: 768px) {
      margin-left: 1.5rem;
      margin-bottom: 0;
    }
  }

  @media (min-width: 768px) {
   flex-direction: row; 
  }
`

const Footer = ({ siteTitle }) => (
  <FooterContainer>
    <LogoContainer>
      <Logo />
    </LogoContainer>
    <FooterNavigation>
      <FooterNavigationPrimary>
        <Link to="/services">Services</Link>
        <Link to="/projects">Projects</Link>
        <Link to="/about">About</Link>
        <Link to="/careers">Careers</Link>
        <Link to="/media">Media</Link>
        <Link to="/contact">Contact</Link>
      </FooterNavigationPrimary>
      <p>&copy;{new Date().getFullYear()} Orion Constructors</p>
    </FooterNavigation>
  </FooterContainer>
)


export default Footer
