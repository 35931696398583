/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

 import React, { useState, useContext } from 'react'
 import PropTypes from "prop-types"
 import styled from "@emotion/styled"
 import "../styles/reset.css"
 import "../styles/globals.scss"
 import { slide as Menu } from 'react-burger-menu'
import { Link } from "gatsby"

 
//  import MessageBar from "../components/global/messagebar"
 import Header from "../components/global/header"
 import Footer from "../components/global/footer"

 const GlobalContain = styled.div`
 `

const MyContext = React.createContext();
const MyProvider = (props) => {
  const [menuOpenState, setMenuOpenState] = useState(false)
  
  return (
    <MyContext.Provider value={{
      isMenuOpen: menuOpenState,
      toggleMenu: () => setMenuOpenState(!menuOpenState),
      stateChangeHandler: (newState) => setMenuOpenState(newState.isOpen)
    }}>
      {props.children}
    </MyContext.Provider>
  )
}
const Button = () => {
  const ctx = useContext(MyContext)
  return (
    <button onClick={ctx.toggleMenu} className={`menutoggle hamburger hamburger--squeeze hamburger--accessible ${ctx.isMenuOpen ? "is-active" : ""}`} type="button">
      <span class="hamburger-box">
        <span class="hamburger-inner"></span>
      </span>
      <span class="hamburger-label">MENU</span>
    </button>
  )
}

const InnerMenu = styled.div`
  font-family: "BS";
  font-size: 1.5rem;
  font-weight: 500;
  color: var(--white);
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  justify-content: center;
  padding-top: 5rem;
  letter-spacing: 0.05rem;

  div {
    margin-bottom: 1.25rem;
  }
`

const Navigation = () => {
  const ctx = useContext(MyContext)

  return (
    <Menu
      right
      noOverlay
      disableAutoFocus
      pageWrapId={ "page-wrap" }
      outerContainerId={ "outer-container" }
      customBurgerIcon={false}
      isOpen={ctx.isMenuOpen}
      onStateChange={(state) => ctx.stateChangeHandler(state)}
    >
      <InnerMenu>
        <div><Link to="/projects">Projects</Link></div>
        <div><Link to="/services">Services</Link></div>
        <div><Link to="/about">About</Link></div>
        <div><Link to="/careers">Careers</Link></div>
        <div><Link to="/media">Media</Link></div>
        <div><Link to="/contact">Contact</Link></div>
      </InnerMenu>
      </Menu>
  )
}

 
 const Layout = ({ children }) => {
 
   return (
     <GlobalContain id="outer-container">
       {/* <MessageBar/> */}
       <Header/>
       <MyProvider>
          <div>
            <Button />
            <Navigation />
          </div>
        </MyProvider>
       <main id="page-wrap">{children}</main>
       <Footer/>
     </GlobalContain>
   )
 }
 
 Layout.propTypes = {
   children: PropTypes.node.isRequired,
 }
 
 export default Layout
 